.ul-form__radio {
  position: relative;
}
span.checkmark.ul-radio__position {
  position: absolute;
  /* bottom: -22px; */
  top: -8px;
  left: 4px;
}

.redesign-input {
  width: 100%;
  padding: 8px 12px;
  border-radius: 12px;
  font-size: 14px !important;
  line-height: 22px;
  background: white;
  font-weight: 500;
  border: 1px solid #b3bebe;
  background-color: #ffffff;
  border-radius: 12px;
  cursor: pointer;

  &:hover {
    border: 1px solid #b3bebe;
    background-color: #f2f5f5;
  }
}
