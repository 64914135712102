.table-search-bar {
  height: fit-content;
  position: relative;
  margin: 0 12px;

  &:after {
    position: absolute;
    top: 50%;
    left: 24px;
    transform: translate(-50%, -40%);
    color: transparent;
    color: inherit;
    content: url('/assets/images/icons/search.svg');
  }

  input {
    border-radius: 100px;
    padding-left: 40px;
    width: 270px;
  }

  .sr-only {
    display: none;
  }
}

// redesign table
.new-header-table {
  border:  1px solid #B3BEBE;
  border-radius: 12px !important;
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
  tr {
    th {
      background-color: #F9FCFC;
      font-family: 'Quicksand'; 
      color: '#5F6969';
      font-weight: 500;
    }
  }
}
