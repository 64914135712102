.dashboard-layout-wrap {
  .card-body.welcome,
  .slider-announcement {
    height: 140px;
  }
  .slider-announcement {
    background-color: $asure-neutral-80;
    flex-direction: column;
    .carousel {
      margin-top: 5px;
      width: 100%;
      height: 100%;
      .carousel-inner,
      .carousel-item {
        height: 100%;
        .carousel-caption {
          position: absolute;
          right: 0;
          bottom: unset;
          left: 0;
          padding-top: 0;
          padding-bottom: 0;
          color: #fff;
          text-align: left;
        }
      }
      .carousel-indicators {
        top: -20px;
        bottom: unset;
        left: unset;
        right: 0;
        margin-right: unset;
        margin-bottom: unset;
      }
    }
  }
  .statistic-value {
    color: $asure-primary-100;
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
  }
  .quick-links {
    li {
      margin-bottom: 10px;
      a {
        color: $asure-neutral-70;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
  .statistics {
    .simple-card-title {
      .text-capitalize {
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
}

.popup-day-in-calendar,
.shift-detail-in-calendar {
  .label {
    color: var(--Neutral-100, #000);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
  .box {
    border: 1px solid $asure-neutral-70;
    border-radius: 12px;
    height: 100%;
    .header {
      padding: 10px 15px;
      display: flex;
      align-items: center;
      .title {
        font-size: 20px;
        font-weight: 600;
        margin-left: 10px;
      }
    }
    thead.foo {
      background-color: #f2f2f2;
    }
    .table > :not(:last-child) > :last-child > * {
      border-bottom-color: transparent;
    }
    td,
    th {
      padding: 15px;
    }
  }
  .modal-header {
    border-bottom: none;
    .modal-title {
      font-size: 20px;
      font-family: 'Quicksand', sans-serif;
      font-weight: 600;
    }
  }
  .modal-footer {
    border-top: none;
  }
}

@media (min-width: 1200px) {
  .popup-day-in-calendar {
    .modal-dialog {
      // max-width: calc(100% - 100px) !important;
      // .modal-body {
      //     padding: 20px 25px;
      // }

      max-width: calc(100% - 500px) !important;
      .modal-body {
        padding: 20px 25px;
      }
    }
  }
}

@media (min-width: 1024px) {
  .popup-day-in-calendar {
    .modal-dialog {
      max-width: calc(100% - 252px);
    }
  }
}

@media (max-width: 767px) {
}

// redesign dashboard
.redesign-dashboard {
  width: 100%;
  height: 95vh;
  font-family: 'Quicksand', sans-serif !important;

  .redesign-dashboard-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    width: 100%;
    color: #2E3333;
    font-size: 20px;
    font-weight: 700;
    font-family: 'Quicksand', sans-serif !important;
  }

  .dashboard-header {
    display: flex;
    align-items: center;
    padding: 1rem 2rem;
    justify-content: space-between;
    // background-color: white;
    // border-bottom: 1px solid #1A1A1A14;


    .header-actions {
      display: flex;
      gap: 10px;
      align-items: center;

      .button-header {
        all: unset;
        padding: 8px 12px;
        display: flex;
        gap: 5px;
        font-family: 'Quicksand', sans-serif !important;
        align-items: center;
        font-weight: bold;
        cursor: pointer;
        font-size: 16px;
        line-height: 24px;

        &.outline {
          border: 1px solid #b3bebe;
          border-radius: 12px;

          &:hover {
            background-color: #F2F5F5;
          }
        }

        &.primary {
          background-color: #02b9b0;
          color: white;
          border-radius: 12px;

          &:hover {
            background-color: #029991;
          }
        }
      }
    }
  }

  .dashboard-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 100%;
    height: 85vh;
    flex: 1;

    .setup-org {
      width: 100%;
      height: 100%;
      background-color: white;
      border: 1px solid #b3bebe;
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 40px;
      padding: 30px;

      .setup-steps {
        display: flex;
        flex-direction: column;
        gap: 50px;

        .step-number {
          font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #F2F5F5;
          color: #B3BEBE;
          border-radius: 8px;
          font-size: 16px;
          width: 40px;
          height: 40px;
          position: relative;

          &.active {
            border: 1px solid #02b9b0;
            color: #1a1a1ab2;
            background-color: white;
          }

          &.completed {
            border: 1px solid #02B9B0;
            color: white;
            background-color: #02B9B0;

            &::before {
              background-color: #02B9B0
            }
          }

          &:last-child::before {
            display: none;
          }

          &::before {
            content: '';
            position: absolute;
            border-radius: 100px;
            background-color: #f2f5f5;
            width: 4px;
            height: 100%;
            bottom: -115%;
          }
        }
      }

      .setup-illustration {
        width: 160px;
        height: 160px;

        img {
          width: 100% !important;
          height: 100% !important;
        }
      }

      .setup-content {
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 10px;
        width: 400px;

        .title {
          font-weight: bold;
          font-size: 30px;
          line-height: 38px; 
          font-family: 'Quicksand', sans-serif !important;
          color: #2e3333;
          font-weight: 700;
        }
        .description {
          font-family: 'Quicksand', sans-serif !important;
          color: #5f6969;
          font-size: 16px;
          line-height: 24px;
          font-weight: 500;
        }

        .button-primary {
          all: unset;
          width: 153px !important;
          height: 44px !important;
          font-family: 'Quicksand', sans-serif !important;
          display: flex;
          font-size: 16px;
          line-height: 24px;
          justify-content: center;
          gap: 10px;
          align-items: center;
          font-weight: bold;
          cursor: pointer;
          border-radius: 12px;
          background-color: #02b9b0;
          color: white;

          &:hover {
            background-color: #029991;
          }
          
          svg {
            path {
              stroke: white;
            }
          }
        }

        .button-outline {
          all: unset;
          width: 113px;
          padding: 8px 10px;
          font-family: 'Quicksand', sans-serif !important;
          display: flex;
          justify-content: center;
          gap: 10px;
          align-items: center;
          font-weight: bold;
          cursor: pointer;
          border: 1px solid #b3bebe;
          border-radius: 12px;

          &:hover {
            background-color: #F2F5F5;
          }
      
          &:focus {
            outline: 2px solid #02B9B0;
      
          }
        }
      }
    }
  }
}
