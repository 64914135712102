// .dropdown-toggle {
//   position: relative;
//   &.btn {
//     // border: 1px solid $gray-200;
//     // border-radius: 40px;
//     // height: 40px;
//     // line-height: 40px;
//     // padding: 0 28px 0 20px;
//     // background: $gray-100;
//     padding-right: 28px;
//   }
//   &::after {
//     position: absolute;
//     top: calc(50% - 2px);
//     right: 10px !important;
//   }
// }

.dropdown-menu {
  border: 0;
  box-shadow: 0 1px 15px 1px rgba(0, 0, 0, 0.04), 0 1px 6px rgba(0, 0, 0, 0.08);
  min-width: 15rem
}

.dropdown-item {
  padding: 0.42rem 1.5rem;
}

.menu-icon-grid {
  width: 220px;
  padding: 0 8px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  > a {
    display: inline-flex;
    width: 6rem;
    flex-direction: column;
    align-items: center;
    padding: 18px 12px;
    color: $gray-800;
    border-radius: 4px;
    i {
      font-size: 28px;
      margin-bottom: 4px;
    }
    &:hover {
      background: $primary;
      color: $white;
    }
  }
}

.mega-menu {
  position: relative;
  .dropdown-menu {
    left: auto;
    right: 0;
    top: 10px !important;
    width: calc(100vw - 120px);
    min-width: calc(100vw - 120px);
    max-width: 1200px;
    padding: 0;
    overflow: hidden;
    max-height: calc(100vh - 100px);
    overflow-y: scroll;
    .bg-img {
      background: linear-gradient(to right, $primary, $info);
    }
    .bg-img,
    .bg-img .title {
      color: #ffffff;
    }
    // .menu-icon-grid {
    //     margin-left: -2rem;
    // }
    ul.links {
      list-style: none;
      margin: 0;
      padding: 0;
      column-count: 2;
      li a {
        display: inline-block;
        margin-bottom: 8px;
        color: $gray-600;
        &:hover {
          color: $primary;
        }
      }
    }
  }
}

.widget_dropdown {
  .dropdown-menu {
    left: auto !important;
    right: 2px !important;
  }
}

@media (max-width: 767px) {
  .mega-menu {
    .dropdown-menu {
      width: calc(100vw - 10px);
    }
  }
}

[dir='rtl'] {
  .mega-menu {
    .dropdown-menu {
      left: 0 !important;
      right: 0 !important;
      margin: auto !important;
    }
  }
}

// redesign dropdown
.custom-dropdown {
  position: relative;

  .dropdown-search {
    padding: 8px;
    position: sticky;
    top: 0;
    background-color: white;
    border-bottom: 1px solid #eee;
    z-index: 1;
    position: relative;

    svg {
      position: absolute;
      left: 16px;
      top: 50%;
      transform: translateY(-50%);
      color: #A3ABAB;
    }

    input {
      width: 100%;
      padding: 8px 12px 8px 36px;
      border: 1px solid #E5E9E9;
      border-radius: 8px;
      font-size: 14px;
      outline: none;

      &:focus {
        border-color: #00ADA3;
      }

      &::placeholder {
        color: #A3ABAB;
      }
    }
  }

  .dropdown-options {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
    transition: all 0.2s ease;
    position: absolute;
    width: 100%;
    background: white;
    border: 1px solid #E5E9E9;
    border-radius: 8px;
    margin-top: 4px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    pointer-events: none;

    &.show {
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
      pointer-events: auto;
    }
  }

  .dropdown-no-results {
    padding: 12px;
    text-align: center;
    color: #A3ABAB;
    font-size: 14px;
  }

  .dropdown-option.highlighted {
    background-color: #f5f5f5;
  }  
}