@keyframes zoomIn {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spin {
  animation: spin 2s infinite linear;
}

// Modal animations
// .modal-animation-enter {
//   opacity: 0;
// }

// .modal-animation-enter-active {
//   opacity: 1;
//   transition: opacity 300ms ease-out;
// }

// .modal-animation-exit {
//   opacity: 1;
// }

// .modal-animation-exit-active {
//   opacity: 0;
//   transition: opacity 300ms ease-out;
// }

// .modal-overlay {
//   position: fixed;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   background-color: rgba(0, 0, 0, 0.5);
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   z-index: 1050;
// }
