@import 'sidebar-compact/layout-sidebar-compact';
@import 'sidebar-large/layout-sidebar-large';
@import 'horizontal-bar/layout-horizontal-bar';
@import 'footer';
@import 'vertical-navbar/vertical-navbar';

// Common Layout Styles

// Sidebar multilevel dropdown
.sidebar-left-secondary {
  .childNav {
    li.nav-item {
      .dd-arrow {
        transform: rotate(-90deg);
      }
      &.open {
        > a {
          background: $gray-200;
          > .dd-arrow {
            transform: rotate(0deg);
          }
        }
        // > .submenu {
        //   max-height: 1000px;
        // }
      }
      .submenu {
        margin: 0;
        padding: 0;
        list-style: none;
        // max-height: 0;
        overflow: hidden;
        transition: all 0.3s ease-in;
        background: $gray-100;
        > li {
          a {
            padding-left: 50px;
          }
        }
      }
    }
  }
}

[dir='rtl'] {
  .notification-dropdown .dropdown-item .notification-details {
    text-align: right;
  }
  .main-header .user {
    margin-left: 2rem;
    margin-right: 0;
  }
  .sidebar-left-secondary {
    .childNav {
      li.nav-item {
        .dd-arrow {
          margin-right: auto;
          margin-left: unset;
        }
      }
    }
  }
}

// sidebar-blank-2
.active-color {
  height: 40px;
  padding: 0 12px 0 24px;
  border-radius: 0 35px 35px 0;
  margin-right: 16px;
  background-color: #663399;
  color: #fff !important;
}

#dropdown-custom-toggle {
  background-color: transparent !important;
  color: #7a8484;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Quicksand';
  font-weight: 700;
  letter-spacing: 2px;
  padding-top: 0;
  padding-bottom: 0;
  height: 24px;
  padding-right: 8px;
  padding-left: 8px;
  img {
    position: relative;
    top: -1px;
    margin-left: 23px;
  }
  &::after {
    display: none !important; /* Ẩn icon mặc định */
  }
  &:active,
  &:focus {
    background-color: transparent !important;
    box-shadow: none !important;
  }
}

.module-manage {
  color: #7a8484;
  font-size: 16px;
  line-height: 24px;
  font-family: 'Quicksand';
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-align: left;
  padding-left: 20px;
  margin-bottom: 12px;
}

.navigation-left {
  .org-active {
    background-color: #f2f5f5;
    border-radius: 12px;
    padding: 8px 10px;
    border: 1px solid #b3bebe;
  }
  .dropdown-organization {
    .dropdown-menu {
      min-width: unset !important;
      > span,
      > a {
        max-width: 196px;
      }
      box-shadow: 2px 2px 0px #01777129;
      border: 1px solid #b3bebe;
      padding: 10px 8px;
      border-radius: 12px;
      transform: none;
      width: 220px;
      .dropdown-item {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 10px 8px;
        margin-bottom: 4px;
      }
      .text-dropdown-label {
        font-family: 'Quicksand';
        font-size: 12px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 2px;
        text-align: left;
        color: #7a8484;
      }
      .text-dropdown-current-org {
        background-color: #f2f5f5;
        border-radius: 12px;
        padding: 10px 8px;
        margin-bottom: 12px;
      }
      .menu-add-org {
        border: 1px solid #2e3333;
        border-radius: 12px;
        padding: 12px;
        font-family: 'Quicksand';
        font-size: 14px;
        font-weight: 600;
        line-height: 22px;
        text-align: center;
        background-color: #fff;
        color: #181b1b;
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
  .profile-dropdown {
    padding: 10px 8px;
    .profile {
      display: flex;
      align-items: center;
      gap: 10px;
      img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }
      .name {
        text-align: left;
        .username {
          font-weight: 500;
          font-family: 'Quicksand';
          font-size: 12px;
          line-height: 18px;
        }
        .email {
          color: rgba(95, 105, 105, 1);
          font-weight: 500;
          font-family: 'Quicksand';
          font-size: 12px;
          line-height: 18px;
        }
      }
    }
  }
}
.progress-bar-custom {
  padding: 0 24px;
  cursor: pointer;
  .divider {
    border-top: 1px solid #f2f5f5;
    margin-bottom: 20px;
  }
  .plan {
    margin-bottom: 20px;
    font-family: 'Quicksand';
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    display: flex;
    gap: 10px;
    justify-content: space-between;
  }
  .progress {
    &.full {
      .progress-bar {
        background-color: #ff7c11;
      }
    }
    margin-top: 8px;
    height: 4px !important;
    .progress-bar {
      background-color: #02b9b0;
    }
  }
}
