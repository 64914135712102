@import 'vars';
@import 'header';

.app-admin-wrap {
  width: 100%;
}

.main-content-wrap {
  width: 100%;
  float: right;
  // margin-top: $topbar-height;
  transition: all 0.24s ease-in-out;
  padding: 2rem 2rem 0;
  position: relative;
  min-height: calc(100vh - #{$topbar-height});
  background: $white;

  &.sidenav-open {
    width: calc(100% - #{$sidebar-left-width});
  }
}

//SIDEBAR =======
.layout-sidebar-large {
  .sidebar-left-secondary,
  .sidebar-left {
    position: fixed;
    // top: $topbar-height;
    top: 0;
    // height: calc(100vh - #{$topbar-height});
    height: 100%;
    background: $background;
    border-right: 1px solid rgba(26, 26, 26, 0.12);
    background-color: white;
    // box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.06), 0 1px 4px rgba(0, 0, 0, 0.08);
  }

  .sidebar-left {
    left: calc(-#{$sidebar-left-width} - #{$side-clearance});
    z-index: 120;
    transition: all 0.24s ease-in-out;

    &.open {
      left: 0;
    }

    .logo {
      text-align: left;
      margin-top: 16px;
      padding: 8px 24px;
      margin-bottom: 20px;
    }

    .navigation-left {
      list-style: none;
      text-align: center;
      width: $sidebar-left-width;
      // height: 100%;
      margin: 0;
      padding: 0;

      .nav-item {
        position: relative;
        display: block;
        width: 100%;
        color: #2e3333;
        cursor: pointer;
        padding: 0 16px;
        margin-bottom: 12px;

        // border-bottom: 1px solid $gray-300;
        &:focus,
        &:active {
          outline: none;
        }

        &.lvl1 {
          text-align: center;
        }

        &.active {
          color: #4d4d4d;

          .nav-item-hold {
            color: #4d4d4d;
          }
        }

        .org-item {
          &:hover {
            background-color: #f2f5f5;
            border-radius: 12px;

            .nav-text,
            i {
              color: $asure-secondary-100 !important;
            }
          }
          &:focus {
            border-radius: 12px;
            border: 2px solid #02b9b0 !important;
          }
        }

        .nav-item-hold {
          display: flex;
          align-items: center;
          width: 100%;
          padding: 10px 8px;
          border: 1px solid #ffffff;
          color: #2e3333;
          height: 44px;

          &:focus,
          &:active {
            outline: none;
          }

          .feather {
            font-size: 32px;
            height: 32px;
            width: 32px;
            display: block;
            margin: 0 auto 6px;
          }

          .nav-icon {
            font-size: 24px;
            height: 24px;
            width: 24px;
            display: block;
            margin: 0 10px 0 0;
          }

          .nav-text {
            font-size: 16px;
            line-height: 16px;
            display: block;
            font-weight: 400;
          }

          .nav-text-no-active {
            font-size: 16px;
            line-height: 16px;
            display: block;
            font-weight: 400;
          }

          .nav-text-active {
            font-size: 16px;
            line-height: 16px;
            display: block;
            font-weight: 400;
            color: #00cdc2;
          }

          a {
            color: $heading;
          }

          &:hover {
            background-color: #f2f5f5;
            border-radius: 12px;

            .nav-text,
            i {
              color: $asure-secondary-100 !important;
            }
          }

          &:focus {
            border: 2px solid #02b9b0;
          }

          &.active {
            background-color: #f2f5f5;
            border: 1px solid #b3bebe;
            // background: linear-gradient(0deg, rgba(0, 205, 194, 0.1), rgba(0, 205, 194, 0.1)), #ffffff;
            border-radius: 12px;

            .nav-text,
            i {
              color: $asure-secondary-100 !important;
            }
          }

          &:hover {
            .nav-text {
              color: #4d4d4d;
            }
          }
        }

        &.active .triangle {
          position: absolute;
          width: 0;
          height: 0;
          right: 0;
          bottom: 0;
          border-style: solid;
          border-width: 0 0 30px 30px;
          border-color: transparent transparent $primary transparent;
        }

        &:first-child {
          margin-top: 10px;
        }
      }
    }
  }

  // SIDENAV SECONDARY
  .sidebar-left-secondary {
    left: calc(-#{$sidebar-left-secondary-width} - #{$side-clearance});
    z-index: 89;
    width: $sidebar-left-secondary-width;
    padding: 0.75rem 0;
    transition: all 0.24s ease-in-out;
    background: $background;

    &.open {
      left: $sidebar-left-width;
    }

    header {
      display: none;
    }

    .childNav {
      list-style: none;
      padding: 0;

      li.nav-item {
        display: block;

        a,
        > div {
          color: $heading;
          text-transform: capitalize;
          display: flex;
          align-items: center;
          font-size: 13px;
          cursor: pointer;
          padding: 12px 24px;
          transition: 0.15s all ease-in;

          &:hover {
            background: $gray-200;
          }

          &.open {
            color: $primary;
          }

          .nav-icon {
            font-size: 18px;
            margin-right: 8px;
            vertical-align: middle;
            color: $gray-600;
          }

          .item-name {
            vertical-align: middle;
            font-weight: 400;
          }

          .dd-arrow {
            margin-left: auto;
            font-size: 11px;
            transition: all 0.3s ease-in;
          }
        }

        a.selected {
          background: #e8e8e8;

          .nav-icon {
            color: $primary;
          }

          .item-name {
            color: $primary;
          }
        }
      }
    }

    & > .childNav {
      margin: 0;
    }

    li.nav-item {
      &.open {
        // > div {
        > a,
        > div {
          > .dd-arrow {
            transform: rotate(0deg);
          }
        }

        > .childNav {
          max-height: 1000px;
          overflow: visible;
        }

        // }
      }

      > div {
        > a,
        > div {
          > .dd-arrow {
            transition: all 0.4s ease-in-out;
          }
        }

        > .childNav {
          max-height: 0;
          overflow: hidden;
          background: $background;
          transition: all 0.4s ease-in-out;

          li.nav-item a {
            padding: 12px 12px 12px 50px;
          }
        }
      }
    }
  }

  .sidebar-overlay {
    display: none;
    position: fixed;
    width: calc(100% - #{$sidebar-left-width} - #{$sidebar-left-secondary-width});
    height: calc(100vh - #{$topbar-height});
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0);
    z-index: 101;
    cursor: w-resize;

    &.open {
      display: block;
    }
  }
}

.module-loader {
  position: fixed;
  background: rgba($background, 0.5);
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 9;

  .loader,
  .spinner {
    position: fixed;
    top: 45%;
    left: calc(50% + 56px);
    z-index: inherit;
  }
}

@media (max-width: 576px) {
  .main-content-wrap {
    padding: 1.5rem;

    &.sidenav-open {
      width: 100%;
    }
  }

  .main-content-wrap {
    margin-top: $topbar-height-mobile;
  }

  .layout-sidebar-large {
    .sidebar-left-secondary,
    .sidebar-left {
      height: calc(100vh - #{$topbar-height-mobile});
      top: $topbar-height-mobile !important;
    }
  }

  .sidebar-left {
    left: calc(-#{$sidebar-left-width-mobile} - #{$side-clearance});

    .navigation-left {
      width: $sidebar-left-width-mobile;

      .nav-item {
        &.active {
          .triangle {
            border-width: 0 0 24px 24px;
          }
        }

        .nav-item-hold {
          padding: 16px 0;
        }
      }
    }
  }

  .sidebar-left-secondary {
    left: calc(-#{$sidebar-left-secondary-width-mobile} - #{$side-clearance});
    width: $sidebar-left-secondary-width-mobile;

    &.open {
      left: $sidebar-left-width-mobile;
    }
  }

  .sidebar-overlay {
    height: calc(100vh - #{$topbar-height-mobile});
  }
}

[dir='rtl'] {
  .layout-sidebar-large {
    .sidebar-left {
      left: auto !important;
      right: calc(-#{$sidebar-left-width} - #{$side-clearance});

      &.open {
        left: auto !important;
        right: 0;
      }

      .navigation-left .nav-item .triangle {
        transform: rotate(90deg);
        right: auto;
        left: 0;
      }
    }

    .sidebar-left-secondary {
      left: auto !important;
      right: calc(-#{$sidebar-left-secondary-width} - #{$side-clearance});

      &.open {
        left: auto !important;
        right: $sidebar-left-width;
      }

      .childNav li.nav-item a {
        .dd-arrow {
          margin-left: unset !important;
          margin-right: auto;
        }

        .nav-icon {
          margin-left: 8px;
          margin-right: 0;
        }
      }
    }

    .main-content-wrap {
      float: left;
    }

    .sidebar-overlay {
      right: auto !important;
      left: 0;
      cursor: e-resize;
    }
  }
}
