@import 'mixins/mixins';
@import 'palette';
@import '../../vendor/animate.scss/animate.scss';
@import 'animations';
@import 'typography';
@import 'height-width';
@import 'box-shadow';
@import 'layouts/layouts';
@import 'components/components';
@import 'header';
@import 'header-top';
@import 'footer';
@import 'utilities/utilities';
@import 'views/views';

html {
  font-size: 16px;

  ::-webkit-scrollbar {
    width: 1px;
  }
}

body {
  letter-spacing: 0.3px;
  line-height: 1.6;
  background: $background;
  overflow-x: hidden;
}

[tabindex='-1']:focus {
  outline: none;
}

hr {
  margin-top: 2rem;
  margin-bottom: 2rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  height: 0;
}

select,
button,
textarea,
input {
  vertical-align: baseline;
}

div {
  box-sizing: border-box;
}

html,
body {
  &[dir='rtl'],
  &[dir='ltr'] {
    unicode-bidi: embed;
  }
}

bdo[dir='rtl'] {
  direction: rtl;
  unicode-bidi: bidi-override;
}

bdo[dir='ltr'] {
  direction: ltr;
  unicode-bidi: bidi-override;
}

img {
  max-width: 100%;
}

a,
a:focus,
a:hover {
  text-decoration: none;
}

blockquote {
  border-left: 2px solid $gray-200;
  padding-left: 1rem;
  margin-bottom: 1rem;
  font-size: 1.01625rem;
}

.close:not(:disabled):not(.disabled):hover,
.close:not(:disabled):not(.disabled):focus {
  outline: none;
}

.o-hidden {
  overflow: hidden;
}

.separator-breadcrumb {
  margin-bottom: 2rem;
}

@for $i from 1 through 5 {
  .line-height-#{$i} {
    line-height: #{$i};
  }
}

.app-inro-circle {
  text-align: center;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: calc(50% - 150px);

  .big-bubble {
    height: 280px;
    width: 280px;
    margin: 0 auto 20px;
    text-align: center;
    background: $primary;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: 108px;
      color: #ffffff;
    }
  }
}

.loadscreen {
  text-align: center;
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  height: 100vh;
  background: #ffffff;
  z-index: 999;

  .loader {
    position: absolute;
    top: calc(50vh - 50px);
    left: 0;
    right: 0;
    margin: auto;
  }

  .logo {
    display: inline-block !important;
    width: 80px;
    height: 80px;
  }
}

.img-preview {
  overflow: hidden;
  float: left;
  background-color: $gray-200;
  width: 100%;
  text-align: center;
  margin-right: 10px;
  margin-bottom: 10px;
}

.preview-lg {
  width: 200px;
  height: 150px;
}

.preview-md {
  width: 150px;
  height: 120px;
}

.preview-sm {
  width: 100px;
  height: 75px;
}

.custom-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.align-item-center {
  align-items: center;
}

@media (max-width: 576px) {
  .app-inro-circle {
    .big-bubble {
      width: 220px;
      height: 220px;
    }
  }

  button.close {
    float: right;
    font-size: 1.2195rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: 0.5;
    position: absolute;
    top: 0;
    right: 5px;
  }
}

[dir='rtl'] {
  .rtl-ps-none {
    .ps__rail-x,
    .ps__rail-y {
      display: none;
    }
  }
}

.ps__rail-x {
  display: none !important;
}

// CUSTOM
.form-check-input {
  width: 20px !important;
  height: 20px !important;
  margin-right: 10px;
  border-width: 2px;

  .form-check & {
    margin-left: -1em;
  }

  &[type='radio'] {
    background-color: #fff;
    border-color: #999999;

    &:focus {
      box-shadow: 0 0 0 0.35rem rgba(242, 242, 242, 1), inset 0 0 0 1rem rgba(242, 242, 242, 1);
    }

    &:disabled {
      background-color: #fff;
      border-color: #cccccc;
      opacity: 1;
    }
  }

  &:checked[type='radio'] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-3 -3 6 6'%3e%3ccircle r='2' fill='%2300CDC2'/%3e%3c/svg%3e");
    background-color: #fff;
    border-color: #00cdc2;

    &:focus {
      box-shadow: 0 0 0 0.35rem rgba(204, 245, 243, 1), inset 0 0 0 0.15rem rgba(204, 245, 243, 1);
    }

    &:disabled {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-3 -3 6 6'%3e%3ccircle r='2' fill='%2399EBE7'/%3e%3c/svg%3e");
      background-color: #fff;
      border-color: #99ebe7;
      opacity: 1;
    }
  }
}

input[type='radio'] {
  margin-top: 0;
}

.checkbox-primary {
  input:checked ~ .checkmark {
    background-color: #00cdc2 !important;
    border: none;
  }

  input:checked ~ .checkmark-header {
    background-color: #00cdc2 !important;
    border: none;
  }
}

.input-left-icon {
  input {
    padding-left: 40px;
  }
}

.btn-icon-only {
  cursor: pointer;
}

.swal2-modal {
  button {
    border-radius: 20px !important;
    height: 40px;
    height: 40px;
    display: inline-flex !important;
    align-items: center;

    &.cancel {
      display: none !important;
    }

    &.swal2-styled.confirm.swal2-confirm {
      background-color: #00cdc2 !important;

      &:focus {
        box-shadow: none !important;
      }
    }
  }

  &.delete {
    .swal2-actions {
      flex-direction: row-reverse;

      .swal2-confirm {
        background-color: $asure-error-100 !important;
        border-color: $asure-error-100 !important;
      }

      .swal2-cancel {
        background-color: #fff !important;
        border: 2px solid $asure-error-100 !important;
        color: $asure-error-100;
      }
    }
  }

  &.alert-without-cancel {
    .swal2-actions {
      .swal2-confirm {
        background-color: $asure-secondary-100 !important;
        border-color: $asure-secondary-100 !important;

        &:hover {
          color: white;
          background-color: #00b9af !important;
          border-color: none !important;
          box-shadow: none !important;
        }

        &:active {
          color: white;
          background-color: #00b9af !important;
          outline: none !important;
          box-shadow: 0 0 0 5px #b3f0ed !important;
        }

        &:focus {
          box-shadow: none !important;
        }
      }

      .swal2-cancel {
        display: none !important;
      }
    }
  }

  &.payslip {
    .swal2-actions {
      flex-direction: row-reverse;

      .swal2-confirm {
        background-color: #00cdc2 !important;
        border-color: #00cdc2 !important;
        color: white;

        &:focus,
        &:hover {
          border-color: #00cdc2 !important;
        }
      }

      .swal2-cancel {
        background-color: #fff !important;
        border: 2px solid #00cdc2 !important;
        color: #00cdc2;
      }
    }
  }
}

thead.foo {
  background-color: #f2f2f2;
}

textarea {
  height: auto !important;
}

.form-group {
  // z-index: 0;

  label {
    font-size: 14px;
    color: #000;
  }
}

.color-neutral-30 {
  color: $asure-neutral-30;
}

.color-neutral-60 {
  color: $asure-neutral-60;
}

.color-neutral-80 {
  color: $asure-neutral-80;
}

input {
  &.form-control {
    &:read-only {
      background: $asure-neutral-30;
    }
  }
}

.form-control-radio {
  height: 40px;
}

.f-opensands {
  font-family: 'Open Sans', sans-serif;
}

.f-quicksand {
  font-family: $font-family-secondary;
}

.btn-primary:hover {
  color: white;
  background-color: #00b9af !important;
  border-color: none !important;
  box-shadow: none !important;
}

.btn-outline-primary:hover {
  background-color: #e5faf9 !important;
  color: #00cdc2 !important;
  border: 1px solid #00cdc2 !important;
  box-shadow: none !important;
}

.btn-primary:active,
.btn-primary:focus {
  color: white;
  background-color: #00b9af !important;
  outline: none !important;
  box-shadow: 0 0 0 5px #b3f0ed !important;
}

.btn-outline-primary:active,
.btn-outline-primary:focus {
  background-color: #e5faf9 !important;
  color: #00cdc2 !important;
  border: 1px solid #00cdc2 !important;
  box-shadow: none !important;
  outline: none;
  box-shadow: 0 0 0 5px #b3f0ed !important;
}

.btn-delete {
  background-color: $asure-error-100 !important;
  min-width: 150px;
}

.side-content-wrap {
  .scrollbar-container {
    &.sidebar-left {
      &.new-sidebar {
        background-color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
    .ps__rail-y {
      display: none;
    }
  }
}

.user {
  .dropdown-item {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    padding: 10px 0 10px 20px;

    img {
      width: 24px !important;
      height: 24px !important;
    }

    &:hover {
      background-color: $asure-primary-20;
    }
  }
}

.notification-dropdown {
  overflow-y: hidden !important;
  transform: translate(0px, 50px) !important;
  border-radius: 12px;
  left: -265px !important;
  width: 365px;

  .notification-title {
    background-color: $asure-primary-30;
    padding: 10px 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 600;
  }
}

.switch-org {
  background-color: unset;
  font-size: 16px;
  font-weight: 600;
  outline: none;

  &:focus {
    box-shadow: none;
  }
}

.custom-card-title {
  font-weight: 600;
}

th.sortable span {
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>") !important;

  &.caret-4-asc {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' height='24' width='24'><g xmlns='http://www.w3.org/2000/svg' transform='matrix(-1 0 0 -1 24 24)'><path d='M7 10l5 5 5-5z' /><path d='M0 0h24v24H0z' fill='none' /></g></svg>") !important;
  }

  background-position: center;
  margin-left: 10px;
  width: 10px;
  height: 10px;
  display: inline-block;

  &::before,
  &::after {
    display: none;
  }
}

.breadcrumb {
  position: relative;
  z-index: 1000;
  width: 43%;

  .capitalize {
    font-size: 16px;
    font-weight: 600;
    color: #808080 !important;
    font-family: $font-family-secondary;
  }
}

@media (max-width: 576px) {
  th {
    width: auto !important;
  }

  .custom-card-title {
    margin-bottom: 10px;
  }
}

.text-color-neutral-70 {
  color: $asure-neutral-70 !important;
}

.text-color-secondary {
  color: $asure-secondary-100 !important;
}

.text-asure-primary {
  color: $asure-primary-100 !important;
}

.text-neutral-90 {
  color: $neutral-90 !important;
}

.text-color-primary {
  color: $primary-100 !important;
}

.text-color-green {
  color: #3cc13b !important;
}

.bg-red {
  background-color: #e20c00 !important;
}

.bg-gray {
  background-color: #e5e5e5 !important;
}

.bg-purple {
  background-color: #deddf7 !important;
}

.bg-light-blue {
  background-color: #ccf5f3 !important;
}

.bg-secondary-60 {
  background-color: $asure-secondary-60 !important;
}

.bg-neutral-5 {
  background-color: $asure-neutral-5 !important;
}

.font-size-10 {
  font-size: 10px;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-20 {
  font-size: 20px;
}

.font-size-30 {
  font-size: 30px;
}

.font-size-48 {
  font-size: 48px;
}

.bg-primary-20 {
  background-color: $asure-primary-20;
}

.bg-gradient {
  background: rgba(255, 240, 204, 255);
}

.bg-gray {
  background: #e5e5e5;
}

.rounded-xl {
  border-radius: 20px;
}

.rounded-2xl {
  border-radius: 30px !important;
}

.rounded-lg {
  border-radius: 12px;
}

.rounded-sm {
  border-radius: 6px;
}

.ant-picker {
  background: #e5e5e5;
  height: 40px;
}

.pb-25px {
  padding-bottom: 25px;
}

.padding-inherit {
  padding: inherit;
}

.w-full {
  width: 100%;
}

.w-80pc {
  width: 80%;
}

.w-fit {
  width: fit-content;
}

.width-130 {
  width: 130px;
}

.border-secondary-100 {
  border: 1px solid $asure-secondary-100;
}

.border-top-gray {
  border-top: 1px solid #e5e5e5;
}

.border-radius-20 {
  border-radius: 20px;
}

.font-size-14 {
  font-size: 14px;
}

.ml-10 {
  margin-left: 10px;
}

.text-black {
  color: #000;
}

.text-gray {
  color: #4d4d4d;
}

.ant-switch-checked {
  background: #3cc13b !important;
}

.items-center {
  align-items: center;
}

.items-start {
  align-items: flex-start;
}

.float-right {
  float: right;
}

.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-around {
  justify-content: space-around;
}

.justify-between {
  justify-content: space-between;
}

.gap-5 {
  gap: 5px;
}

.gap-10 {
  gap: 10px;
}

.gap-16 {
  gap: 16px;
}

.gap-20 {
  gap: 20px;
}

.cursor-pointer {
  cursor: pointer;
}

.sa-table-header {
  background-color: #f2f2f2;

  th {
    padding: 1rem 1.5rem;
  }
}

.sa-table-row {
  border-bottom: '1px solid #F2F2F2';

  td {
    padding: 1rem 1.5rem;
  }
}

.sa-card {
  .card-body {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.text-black {
  color: black;
}

.font-weight-bold {
  font-weight: 700;
}

.font-weight-semibold {
  font-weight: 600;
}

.font-weight-500 {
  font-weight: 500;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.margin-center {
  margin: 0 auto;
}

.lh-24 {
  line-height: 24px;
}

.lh-18 {
  line-height: 18px;
}

.h-full {
  height: 100%;
}

.sa-table {
  .react-bootstrap-table-pagination {
    padding: 0 15px;
  }
}

.p-0 {
  padding: 0;
}

.p-5px {
  padding: 5px;
}

.p-10 {
  padding: 10px;
}

.p-20 {
  padding: 20px;
}

.p-30 {
  padding: 30px;
}

.pl-20 {
  padding-left: 20px !important;
}

.p-x-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.p-x-20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.p-x-30 {
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.p-y-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.mt-20 {
  margin-top: 20px;
}

.mt-10 {
  margin-top: 10px !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-5px {
  margin-bottom: 5px;
}

.mb-15px {
  margin-bottom: 15px;
}

.mb-30 {
  margin-bottom: 30px;
}

.h-10 {
  height: 40px;
}

.all-unset {
  all: unset;
}

.overflow-hidden {
  overflow: hidden;
}

.fixed {
  position: fixed;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.date-input {
  input {
    border: 0 !important;
  }
}

.map-marker {
  width: 150px;
  height: 150px;
  border-radius: 100%;
  background-color: rgba(226, 12, 0, 0.5);
  border: 2px solid #e20c00;
  position: relative;
  left: -60px;
  top: -50px;
  opacity: 0.3;
  position: absolute;
}

.form-control:focus {
  box-shadow: 0 0 0 1px rgba(102, 51, 153, 0.2) !important;
}

.auth-content .form-control:focus {
  box-shadow: 0 0 0 0px rgba(102, 51, 153, 0.2) !important;
  border: 1px solid #02b9af !important;
}

// avatar
.user__placeholder {
  width: 56px;
  height: 56px;
  display: grid;
  color: white;
  place-items: center;
  border-radius: 50%;
  position: relative;

  img {
    width: 56px;
    height: 56px;
    object-fit: cover;
    border-radius: 50%;
  }
}

.user-online {
  position: absolute;
  height: 10px;
  width: 10px;
  border: 2px solid #fff;
  background: #3cc13b;
  border-radius: 50%;
  bottom: 2px;
  right: 5px;
  z-index: 99;
}

.more-user {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #00cdc2;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.room-chat-item {
  border-radius: 12px;
  padding: 10px;
  margin-right: 20px;
  margin-bottom: 30px;
  background: #f2f2f2;
  transition: 1s;

  &:hover {
    background: #cccccc;
    transition: 1s;
  }
}

.room-active {
  background: #cccccc !important;
  transition: 1s;
}

.message-left {
  border-radius: 16px 16px 16px 0px;
  background: #fff;
  text-align: left;
  width: fit-content;
  padding: 5px 10px;
  max-width: 80%;

  .message-reply-left {
    margin-top: 5px;
    background: #e5e5e5;
    width: fit-content;
    padding: 2px 10px;
    border-radius: 8px 8px 8px 0;
  }

  .message-block-forward {
    margin-top: 5px;
    background: rgba(208, 206, 195, 0.5);
    width: fit-content;
    padding: 2px 10px;
    border-radius: 8px 8px 0px 8px;
  }
}

.message-right {
  padding: 5px 10px;
  border-radius: 16px 16px 0px 16px;
  background: #994aff;
  color: #fff;
  text-align: right;
  width: fit-content;
  max-width: 80%;

  .message-reply-right {
    margin-top: 5px;
    // background: rgba(208, 206, 195, 0.5);
    background: #cdccf3;
    color: #000;
    width: fit-content;
    padding: 2px 10px;
    border-radius: 8px 8px 0px 8px;
  }

  .message-block-forward {
    margin-top: 5px;
    background: rgba(208, 206, 195, 0.5);
    width: fit-content;
    padding: 2px 10px;
    border-radius: 8px 8px 0px 8px;
  }
}

.textarea-message {
  padding: 10px;
  width: 100%;
  background: #fff;

  input {
    width: 100%;
    border: none !important;
    outline: none !important;
  }
}

.emoji-block {
  position: absolute;
  bottom: 50px;
}

.fc-day-cell-class {
  height: 50px;
}

.fc-day-cell-class {
  height: 50px;
}

.fc-day-cell-class .fc-event-today {
  cursor: pointer;
}

.ant-popover-inner {
  padding: 0 !important;
}

.user-tab {
  background: #e5e5e5;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;

  &:hover {
    background: #cccccc;
  }
}

.user-active {
  background: #cccccc !important;
}

// div.form-group:has(.react-datepicker-wrapper, .rdtPicker) {
//   z-index: 1;
// }

.org-logo {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
}

.divider {
  width: 100%;
  height: 1px;
  background-color: #dee2e6;
}

.col-right-offset {
  position: relative;
  top: -48px;
}

.col-title-right-offset {
  position: relative;
  top: -30px;
}

.form-control:disabled {
  background: #b3b3b3 !important;

  &::placeholder {
    color: $gray-300;
  }
}

.employee-doc-container {
  position: relative;
  width: 130px;
  height: 150px;
  margin-right: 20px;

  .employee-doc-img {
    height: 150px;
    width: 100px;
    object-fit: cover;
  }

  .img-delete {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    height: 18px;
    width: 18px;
  }
}

.rdt {
  width: 100%;
}

.working-hour-item {
  border: 1px solid $asure-neutral-70;
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 20px;
  height: calc(100% - 24px);
}

// for date picker

.ke-date-picker-container {
  display: flex;
  align-items: center;
  width: 100vw;
  height: 40px;
  border-radius: 8px;
  position: relative;

  &.apply-shift-template {
    width: auto !important;

    input {
      box-shadow: 0 0 0 1px rgba(102, 51, 153, 0.2) !important;
    }
  }

  .date-input {
    width: 100%;
    height: 100%;
    // z-index: 1;
    position: relative;

    .react-datepicker-wrapper {
      width: 100% !important;
    }

    input {
      width: 100%;
      height: 40px;
      border: none;
      background: transparent;
      z-index: 1;
      position: relative;
      padding: 0 12px;
    }

    textarea:focus,
    input:focus {
      outline: none;
    }
  }

  .icon {
    position: absolute;
    right: 12px;
    z-index: 0;

    img {
      width: 24px;
    }
  }
}

.datepicker-style {
  padding: 0 !important;
}

.z-1 {
  z-index: 1;
}

.hidden {
  display: none !important;
}

.user-block {
  height: 40vh;
  overflow: auto;

  .group-user-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 12px 12px 24px;
    margin-right: 12px;
    border-radius: 12px;
    margin-bottom: 12px;
    cursor: pointer;

    .username {
      width: 230px;
    }

    .username-edit {
      width: 160px;
    }

    .checkbox-icon {
      width: 24px;
      height: 24px;
    }
  }

  .group-user-item-checked {
    background: #f2f2f2;
  }
}

.group-cover {
  width: 43px;
  height: 43px;
  object-fit: cover;
  border-radius: 50%;
  cursor: pointer;
}

.tag-user-block {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  padding-top: 20px;
  padding-right: 10px;
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;

  .tag-user {
    color: #00cdc2;
    border: 1px solid #00cdc2;
    border-radius: 4px;
    background: #e6faf9;
    position: relative;
    padding: 6px 12px;

    .remove-icon {
      position: absolute;
      right: -12px;
      top: -12px;
      z-index: 1;
      cursor: pointer;
    }

    .user-avatar {
      width: 30px;
      height: 30px;
      object-fit: cover;
      border-radius: 50%;
    }
  }
}

.board-card {
  height: 142px;
}

/* scrollbar style like macos */
/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #646565;
  border-radius: 5px;
}

.fc {
  .fc-daygrid-day-top {
    display: block !important;
    text-align: center;
    margin-top: 10px;
  }

  .fc-header-toolbar {
    .fc-toolbar-chunk {
      display: flex;
      align-items: center;

      .calendar-title {
        font-size: 1.7em;
        font-weight: bold;
      }
    }
  }
}

.line-height-18 {
  line-height: 18px;
}

.line-height-22 {
  line-height: 22px;
}

.line-height-24 {
  line-height: 22px;
}

.line-height-42 {
  line-height: 42px;
}

.message-action {
  position: absolute;
  right: -15px;
  bottom: 0px;
  display: flex;

  img {
    width: 15px;
    height: 12px;
    cursor: pointer;
  }
}

.message-action-item:hover {
  background: #d9d9d9;
  border-radius: 5px;
}

.page-item {
  &:active {
    outline: 5px solid #b3f0ed;
    z-index: 1000;
  }

  &.active .page-link {
    background-color: #00cdc2;
    border-color: #00cdc2;
    opacity: 0.9;
  }

  &.active .page-link:focus {
    color: white !important;
  }

  &.active .page-link:hover {
    border-color: #00cdc2;
    background-color: #00cdc2;
  }

  .page-link:hover {
    color: #808080 !important;
    background-color: #f2f2f2 !important;
  }

  .page-link:focus {
    outline: none !important;
    color: #808080 !important;
    box-shadow: 0 0 5px #b8c0bf !important;
  }

  .page-link:active {
    z-index: 1000;
    outline: 5px solid #b3f0ed;
    box-shadow: none !important;
    border: none;
  }
}

.profile-img {
  position: relative;

  .icon-edit {
    position: absolute;
    right: 10px;
    bottom: 5px;
    cursor: pointer;
  }
}

.chat-media-img {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;

  img {
    width: 90px;
    height: 90px;
    object-fit: cover;
    border-radius: 8px;
  }
}

.total-unread {
  border-radius: 50%;
  height: 26px;
  width: 26px;
  margin-left: 5px;
  font-size: 12px;
  justify-content: center;
  align-items: center;
  display: flex;
  background: #00cdc2 !important;
}

.pe-10 {
  padding-right: 80px !important;
}

.sys-message {
  background: #00cdc24d;
  border-radius: 8px;
  width: fit-content;
  text-align: center;
  padding: 2px 8px;
}

.ant-picker-focused {
  box-shadow: none !important;
}

.ant-picker-clear {
  .anticon-close-circle {
    background: #e5e5e5;
  }
}

.react-bootstrap-table {
  overflow-x: auto;

  .selection-cell-header {
    text-align: center;
  }
}

.text-capitalize {
  text-transform: capitalize;
}

.sign-container {
  position: relative;
  width: 250px;
}

.sigCanvas {
  border: 1px solid #4d4d4d;
  border-radius: 6px;
}

.clear-sign {
  position: absolute;
  bottom: 10px;
  right: 3px;
  cursor: pointer;
}

.img-signature {
  width: 250px;
  height: 150px;
  border: 1px solid #4d4d4d;
  border-radius: 6px;
  object-fit: cover;
}

.disable-sign {
  width: 254px;
  height: 154px;
  border: 1px solid #4d4d4d;
  background: #e5e5e5;
  border-radius: 6px;
  position: absolute;
  top: 0;
}

.z-99 {
  z-index: 99 !important;
}

.toggle-password {
  position: absolute;
  top: 47px;
  right: 18px;
}

.w-50 {
  width: 50%;
}

.align-center {
  align-items: center;
}

.form-control.rounded,
.form-control.form-control-rounded,
.rdt.form-control-rounded .form-control {
  border-radius: 6px;
}

.chat-group-avatar {
  position: relative;
  width: fit-content;

  .edit-group-avatar {
    display: none;
    position: absolute;
    height: 56px;
    width: 56px;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    background: #4d4d4d;
    opacity: 0.8;
    border-radius: 100%;
  }

  &:hover {
    .edit-group-avatar {
      display: flex;
    }
  }
}

.h60 {
  min-height: 60px;
}

.break-words {
  white-space: break-spaces;
}

.btn-qty {
  background-repeat: no-repeat;
  background-position: center;
  padding: 20px 0;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.btn-descrease {
  background-image: url('/assets/images/icons/decrease.svg');
}

.btn-increase {
  background-image: url('/assets/images/icons/increase.svg');
}

.swal2-header,
.swal2-content {
  padding: 0 !important;
}

// calendar
.fc-daygrid-day-number,
.fc-col-header-cell-cushion,
.fc-more-link {
  color: #000000 !important;
}

.icon-checked {
  width: 24px;
}

.text-right {
  text-align: right !important;
}

.f-weight-600 {
  font-weight: 600;
}

// sweet alert
.kelick-alert {
  background-color: white !important;
  border-radius: 8px !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08) !important;
  width: 400px !important;
  max-width: 90% !important;
  position: fixed !important;
  left: 25%;
  top: 10px;
  transform: translateX(-50%) !important;
  padding: 0px !important;
}

.kelick-alert .swal2-icon {
  margin: 0.5rem auto !important;
  width: 35px !important;
  height: 35px !important;
  border-width: 2px !important;
}

.kelick-alert .swal2-icon-content {
  font-size: 1.25rem !important;
}

.kelick-alert .swal2-title {
  color: #333 !important;
  font-size: 0.925rem !important;
  font-weight: 500 !important;
  padding: 0.5rem 1rem !important;
  margin: 0 !important;
}
// modal bottom animation
.selection-modal {
  position: fixed;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1050;
  // min-width: 300px;

  .selection-count {
    font-size: 14px;
    color: #00cdc2;
    font-weight: 600;
    padding: 10px 24px;
  }

  .action-button {
    font-size: 14px;
    color: #1a1a1a;
    text-decoration: none;
    display: flex;
    gap: 5px;
    align-items: center;
    background: none;
    cursor: pointer;
    padding: 0;
    transition: all 0.2s ease;
    border-left: 1px solid #b3bebe;

    padding: 10px 24px;

    &:hover {
      color: #00cdc2;
      transform: translateY(-1px);
    }

    img {
      margin-right: 8px;
      transition: transform 0.2s ease;
    }

    &:hover img {
      transform: scale(1.1);
    }

    &.delete-btn {
      color: #ff4d4f;
      &:hover {
        color: #ff7875;
      }
    }

    &.cancel-btn {
      color: #2e3333;
      &:hover {
        color: #4d4d4d;
      }
    }
  }
}

// Modal animation classes
.modal-animation-enter {
  opacity: 0;
  transform: translate(-50%, 100%);
}

.modal-animation-enter-active {
  opacity: 1;
  transform: translate(-50%, 0);
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

.modal-animation-exit {
  opacity: 1;
  transform: translate(-50%, 0);
}

.modal-animation-exit-active {
  opacity: 0;
  transform: translate(-50%, 100%);
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
}

// dashboard input
.input-dashboard {
  color: #1a1a1a !important;
  cursor: pointer !important;

  &:hover {
    border: 1px solid #1a1a1a !important;
    background-color: #f2f5f5;
  }

  &:focus {
    border: 1px solid #02b9b0 !important;
    outline: #02b9b0 !important;
    box-shadow: none !important;
  }
}

// redesign table
.wrapper-table-custom-class {
  .react-bootstrap-table {
    border: 1px solid #b3bebe !important;
    border-radius: 12px !important;
    border-bottom: none !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    // margin-bottom: 20px;
  }

  .table {
    margin-bottom: 0 !important;
    border: 1px solid #b3bebe !important;
    border-radius: 12px !important;
    border-bottom: none !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    --bs-table-striped-bg: white !important;
  }

  .table tbody tr:nth-child(2n) {
    background-color: #f9fcfc !important;
  }
}

.table-custom-class {
  overflow: hidden !important;
  thead,
  tbody,
  tfoot,
  tr,
  td,
  th {
    border: unset !important;
    // border-color: unset;
    // border-style: unset;
    // border-width: 0;
  }

  thead {
    border-top: none !important;
    border-bottom: 1px solid #b3bebe !important;
    tr {
      th {
        padding: 15px !important;

        .sortable {
          width: 100%;
          display: block;

          > div {
            padding-right: 8px;
          }
        }
      }
    }
  }

  tbody > tr {
    border-bottom: 1px solid #b3bebe !important;
    padding: 0px 15px !important;
  }

  tbody > tr > td {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    text-align: start;
    border-bottom: 1px solid #b3bebe !important;

    &:last-child {
      border-bottom: none !important;
    }
  }
}

// custom dropdown
.custom-dropdown {
  position: relative;
  min-width: 120px;
  user-select: none;
}

.dropdown-header {
  padding: 8px 12px;
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #1a1a1a;
  font-weight: 600;
  font-family: $font-family-secondary;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &:hover {
    border: 1px solid #1a1a1a;
    background-color: #f2f5f5;
  }
}

.dropdown-header.open {
  // border-color: #02B9B0;
  border-color: #1a1a1a;
}

.dropdown-arrow {
  transition: transform 0.2s ease;
}

.dropdown-arrow.open {
  transform: rotate(180deg);
}

.dropdown-options {
  position: absolute;
  top: calc(100% + 4px);
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  z-index: 1000;
  font-weight: 600;
  font-family: $font-family-secondary;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.dropdown-option {
  padding: 8px 12px;
  margin: 5px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 14px;
  color: #1a1a1a;
  transition: background-color 0.2s ease;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.dropdown-option:hover {
  background-color: #f7fafc;
}

.dropdown-option.selected {
  background-color: #ebf5ff;
  color: #02b9b0;
}

.dropdown-option:first-child {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.dropdown-option:last-child {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
