// user for organization page
.organization-wrap {
  .buttons-navigation {
    gap: 40px;
    margin-bottom: 40px;
    .btn-navigation {
      min-height: 80px;
      width: 180px;
      padding: 20px;
      box-sizing: border-box;
      background: #ffffff;
      box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.1);
      border-radius: 12px;
      font-weight: 600;
      font-size: 16px;
      color: $asure-neutral-70;
      cursor: pointer;
      &.active {
        background: linear-gradient(0deg, rgba(0, 205, 194, 0.1), rgba(0, 205, 194, 0.1)), #ffffff;
        color: $asure-secondary-100;
      }
    }
  }
  .logo {
    max-height: 40px;
  }
  .rdt.form-control-rounded .form-control {
    border-radius: 0.25rem;
  }
  .org-item {
    padding: 12px 37px;
    background-color: $asure-neutral-5;
    border-radius: 12px;
  }
  .branch-status {
    &.active {
      color: $asure-success-100;
    }
    &.inactive {
      color: $asure-neutral-100;
    }
  }
  .working-hours {
    .working-hour-item {
      border: 1px solid $asure-neutral-70;
      border-radius: 12px;
      padding: 20px;
      margin-bottom: 20px;
      height: calc(100% - 24px);
    }
  }
}

@media (min-width: 1024px) {
}
@media (max-width: 767px) {
  .organization-wrap {
    .buttons-navigation {
      flex-direction: column;
      .btn-navigation {
        width: 100%;
        min-height: auto;
      }
    }
  }
}

// redesign
.redesign-organization {
  width: 100%;
  height: 95vh;
  font-family: 'Quicksand', sans-serif !important; 


  .organization-header {
    // padding: 1rem 2rem;
    background-color: white;

    .title {
      padding: 1rem 2rem 0rem;
    }

    .tab-container {
      margin-top: 24px;
      border-bottom: 1px solid #1a1a1a1f;
      padding: 0rem 2rem;

      .tab-list {
        display: flex;
        gap: 32px;

        .tab-item {
          padding: 12px 0;
          cursor: pointer;
          position: relative;
          color: #757575;
          font-size: 16px;
          line-height: 24px;
          transition: color 0.3s ease;

          &:hover {
            color: #1a1a1a;
          }

          &.active {
            color: #1a1a1a;
            font-weight: 600;

            &:after {
              content: '';
              position: absolute;
              bottom: -1px;
              left: 0;
              width: 100%;
              height: 2px;
              background-color: #1a1a1a;
            }
          }
        }
      }
    }
  }

  .organization-form-wrapper {
    width: 100%;
    min-height: calc(100vh - 131px);
    padding: 1.5rem 0rem 4rem 0rem;
    display: flex;
    justify-content: center;
    background-color: white;

    .organization-form {
      width: 100%;
      max-width: 645px;
      background-color: white;

      .loading-text {
        font-size: 12px;
        color: #666;
        margin-top: 4px;
        font-style: italic;
      }

      .error {
        color: #ff4d4f;
        font-size: 12px;
        margin-top: 4px;
      }

      .form-section {
        border-radius: 12px;
        margin-bottom: 16px;
        border: 2px solid #b3bebe;
        transition: all 0.3s ease;

        &:hover {
          border: 2px solid #029991;
        }

        .section-header {
          padding: 12px 16px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          user-select: none;
          background-color: white;
          border-radius: 12px !important;

          h3 {
            margin: 0;
            font-size: 20px;
            line-height: 32px;
            font-weight: 600;
            color: #2e3333;
          }

          .collapse-icon {
            transition: transform 0.3s ease;
            display: inline-block;
            transform: rotate(180deg);
            color: #757575;

            &.open {
              transform: rotate(0deg);
            }
          }

          .save-info {
            display: flex;
            align-items: center;
            gap: 8px;
            span {
              color: #18b451;
              font-weight: 600;
              font-size: 14px;
              line-height: 22px;
            }
          }

          .warning-info {
            display: flex;
            align-items: center;
            gap: 8px;
            span {
              color: #ff7c11;
              font-weight: 600;
              font-size: 14px;
              line-height: 22px;
            }
          }
        }

        .form-content {
          padding: 0px 16px;
          background-color: transparent;
          border-radius: 12px;
          
          .logo-section {
            margin-bottom: 24px;

            label {
              display: block;
              margin-bottom: 8px;
              color: rgba(26, 26, 26, 0.7);
              font-size: 18px;
              font-weight: 500;
              line-height: 28px;
            }

            .logo-upload {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              gap: 15px;

              .logo-wrapper {
                position: relative;
                .delete-logo {
                  background-color: white;
                  border-radius: 50%;
                  position: absolute;
                  cursor: pointer;
                  top: 0;
                  right: 0px;
                }

                img {
                  width: 60px;
                  height: 60px;
                  object-fit: contain;
                  border-radius: 50%;
                }
              }
              .logo-placeholder {
                display: flex;
                justify-content: flex-start;
                flex-direction: column;
                gap: 2px;

                .edit-logo-btn {
                  width: 87px;
                  height: 36px;
                  border-radius: 12px;
                  background: #f9fcfc;
                  border: 1px solid #b3bebe;
                  font-size: 12px;
                  cursor: pointer;
                  color: #1a1a1a;
                  font-weight: 600;

                  &:hover {
                    background-color: #f2f5f5;
                  }
                  &:focus {
                    outline: 2px solid #02b9b0;
                  }
                }

                .logo-hint {
                  font-size: 18px;
                  line-height: 28px;
                  font-weight: 400;
                  color: #1a1a1a5c;
                }
              }
            }
          }

          .form-group {
            margin-bottom: 16px;
            position: relative;

            label {
              display: block;
              margin-bottom: 8px;
              color: #1a1a1ab2;
              font-size: 16px;
              line-height: 24px;
            }

            .form-control {
              width: 100%;
              padding: 8px 12px;
              border: 1px solid #b3bebe;
              border-radius: 12px;
              font-size: 16px !important;
              line-height: 24px;
              background: white;
              font-weight: 500;
              color: #2e3333 !important;
              cursor: pointer;

              &:hover {
                border: 1px solid #1a1a1a;
                background-color: #f2f5f5;
              }

              &::placeholder {
                color: #b3bebe;
              }

              &:focus {
                outline: none;
                border: 2px solid #02b9b0;
              }
            }
            // style for the post code number option
            .post-code-wrapper {
              position: absolute;
              z-index: 1000;
              display: flex;
              margin-top: 10px;
              gap: 5px;
              flex-direction: column;
              padding: 10px;
              border: 1px solid #1a1a1a;
              border-radius: 12px;
              background-color: white;

              .post-code-options {
                padding: 5px 10px;
                border-radius: 8px;
                color: #1a1a1a;
                cursor: pointer;
                &:hover {
                  background-color: #f7fafc;
                }
              }
            }
            // style for the dropdown
            .custom-dropdown {
              .dropdown-header {
                border: 1px solid #b3bebe;
                border-radius: 12px;
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
                color: #2e3333 !important;

                &:hover {
                  border-color: #1a1a1a;
                }

                &.open {
                  border-color: #1a1a1a;
                }

                .dropdown-arrow {
                  transition: transform 0.2s;
                  color: #757575;

                  &.open {
                    transform: rotate(180deg);
                  }
                }
              }

              .dropdown-options {
                margin-top: 4px;
                border: 1px solid #b3bebe;
                border-radius: 12px;
                background: white;
                box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

                .dropdown-option {
                  padding: 8px 12px;
                  cursor: pointer;
                  font-size: 14px;
                  color: #1a1a1a;

                  &:hover {
                    background: #f9fcfc;
                  }

                  &.selected {
                    background: #e6f7f6;
                    color: #02b9b0;
                  }
                }
              }
            }

            select.form-control {
              appearance: none;
              background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
              background-repeat: no-repeat;
              background-position: right 8px center;
              background-size: 16px;
              padding-right: 32px;
            }

            .error {
              color: #f44336;
              font-size: 12px;
              margin-top: 4px;
            }
          }

          .departments-container {
            .department-input-group {
              margin-bottom: 16px;

              .input-with-action {
                display: flex;
                align-items: center;
                gap: 12px;

                .form-control {
                  flex: 1;
                }

                .remove-department {
                  background: none;
                  border: none;
                  padding: 0;
                  cursor: pointer;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 24px;
                  height: 24px;

                  &:hover {
                    svg {
                      path {
                        stroke: #1a1a1a
                      }
                    }
                  }
                }
              }
            }

            .add-department-btn {
              display: flex;
              width: 102px;
              height: 36px;
              align-items: center;
              background-color: #f9fcfc;
              border: 1px solid #b3bebe;
              border-radius: 12px;
              color: #1a1a1a;
              font-size: 14px;
              line-height: 22px;
              font-weight: 600;
              cursor: pointer;
              margin-bottom: 16px;

              &:hover {
                background-color: #f2f5f5;
              }
              &:focus {
                outline: 2px solid #02b9b0;
              }

              svg {
                margin-right: 4px;
              }
            }
          }
        }
      }

      .form-actions {
        display: flex;
        justify-content: flex-end;
        padding: 0px 0;

        .save-changes-btn {
          background: #02b9b0;
          color: white;
          border: none;
          border-radius: 12px;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          width: 156px;
          height: 44px;
          cursor: pointer;

          &:hover {
            background-color: #029991;
          }

          &:disabled {
            background-color: #f2f5f5;
            color: rgba(26, 26, 26, 0.18);
          }
        }
      }
    }
  }
  .collapse {
    transition: height 0.4s ease !important;
    border-radius: 12px !important;

    &:not(.show) {
      display: block;
      height: 0;
      overflow: hidden;
    }
  }

  .collapsing {
    height: 0;
    overflow: hidden;
    transition: height 0.4s ease !important;
    border-radius: 12px !important;
  }
}

.organization-list-container {
  padding: 24px;

  .organization-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 24px;

    .organization-item {
      background: #ffffff;
      border: 1px solid #e6e8e8;
      border-radius: 16px;
      padding: 24px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      gap: 16px;
      transition: all 0.2s ease;
      cursor: pointer;
      box-shadow: 2px 2px var(--blur-1) 0px #0177711A;

      &:hover {
        box-shadow: 2px 2px 9px 0px rgba(1, 119, 113, 0.1);
      }



      .org-avatar-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;

        .org-avatar {
          width: 35px;
          height: 35px;
          object-fit: contain;
          border-radius: 50%;
        }

        .org-uen {
          color: #5f6969;
          font-weight: 500;
          font-size: 18px;
          line-height: 28px;
        }
      }

      .org-info {
        margin-top: 14px;
        display: flex;
        flex-direction: column;
        gap: 8px;

        .org-name {
          color: #1a1a1a;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
        }

        .org-address {
          color: rgba(26, 26, 26, 0.7);
          font-size: 14px;
          line-height: 22px;
        }
      }

      .edit-org-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        width: 164px;
        height: 36px;
        background: #f9fcfc;
        border: 1px solid #b3bebe;
        border-radius: 12px;
        cursor: pointer;

        span {
          color: #2e3333;
          font-weight: 600;
          font-size: 14px;
          line-height: 22px;
        }

        &:hover {
          background: #e6f7f6;
          border-color: #02b9b0;

          span {
            color: #02b9b0;
          }

          svg path {
            stroke: #02b9b0;
          }
        }
      }

      .upgrade-to-pro-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        width: 164px;
        height: 36px;
        border: none;
        cursor: pointer;
        position: relative;
        background: linear-gradient(90deg, #02b9b0 0%, #fac905 100%);
        border-radius: 12px;
        padding: 1px;

        &::before {
          content: '';
          position: absolute;
          inset: 1px;
          background: #f9fcfc;
          border-radius: 11px;
          z-index: 0;
        }

        span,
        img {
          position: relative;
          z-index: 1;
          color: #1a1a1a;
          font-weight: 600;
          font-size: 14px;
          line-height: 22px;
        }
      }
    }
  }
}

.map-container {
  width: 100%;
  height: 300px;
  position: relative;
  border-radius: 12px;
  overflow: hidden;

  .map-wrapper {
    width: 100%;
    height: 100%;
    position: relative;
  }

  .map-controls {
    position: absolute;
    height: 100%;
    right: 10px;
    top: 0px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    justify-content: space-between;
    z-index: 2;
    padding: 10px 0px;

    .map-control-btn {
      width: 40px;
      height: 40px;
      background: white;
      border: 1px solid #e5e5e5;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      &:hover {
        background: #f5f5f5;
      }

      &.fullscreen {
        margin-bottom: 10px;
      }
    }

    .zoom-controls {
      display: flex;
      gap: 1px;
      background: white;
      border-radius: 8px;
      overflow: hidden;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

      .map-control-btn {
        width: 60px;
        height: 44px;
        border-radius: 0;
        margin: 0;
        border: none;
        border-bottom: 1px solid #e5e5e5;

        &:last-child {
          border-bottom: none;
        }
      }
    }
  }

  .marker {
    position: absolute;
    transform: translate(-50%, -100%);
    cursor: pointer;

    &:hover .marker-text {
      display: block;
    }

    .marker-text {
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      background: #02b9b0;
      font-weight: 700;
      color: white;
      padding: 4px 8px;
      border-radius: 12px;
      font-size: 12px;
      white-space: nowrap;
      margin-bottom: 4px;
    }
  }
}
