body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.react-bootstrap-table.payroll-inside .react-bootstrap-table-pagination {
  visibility: hidden;
}

.hidden-table-header .table-header-wrapper {
  visibility: hidden;
}

.react-bootstrap-table.payroll-container {
  padding: 0px 0px;
}

.react-bootstrap-table.payroll-container .hidden-body-border {
  border-style: hidden;
}
.col-20 {
  width: calc(100% / 5) !important;
}

.col-16 {
  width: calc(100% / 7) !important;
}

.col-14 {
  width: calc(100% / 7) !important;
}

.ant-select-selector {
  background-color: #e5e5e5 !important;
}

.bg-gray-1 {
  background: #e5e5e5 !important;
}

.bg-gray-2 {
  background: #b3b3b3 !important;
}

.bg-new-dashbaord {
  background: #fafcfc !important
}

.ant-select-item-option {
  // color: #ffffff !important;
  // font-size: 0.813rem !important;
  // font-weight: 400 !important;
  // line-height: 1.5 !important;
}
.ant-select-suffix {
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>") !important;
}
// .ant-select-item-option-content {
//   font-size: 10px !important;
// }
.ant-select-arrow {
  width: 12px !important;
  height: 24px !important;
  margin-top: -12px !important;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>") !important;
}

.page-item .page-link {
  font-size: 14px !important;
  font-weight: 600 !important;
}

.edit-desk-booking-modal {
  .modal-dialog.modal-dialog-centered {
    max-width: 800px !important;
  }
  .event-popover-custom {
    padding: 20px;
  }
}

.finance-status-unpaid {
  background: #eeeefb;
  border: 1px solid #9b9ae6;
  color: #5856d6;
  border-radius: 15px;
  height: 30px;
  display: flex;
  align-items: center;
  padding: 0 14px;
  font-weight: 600;
  width: fit-content;
}

.finance-status-paid {
  background: #ebfbfa;
  border: 1px solid;
  border-image-source: linear-gradient(0deg, #ffffff, #ffffff),
    linear-gradient(0deg, rgba(0, 205, 194, 0.6), rgba(0, 205, 194, 0.6));
  color: #00cdc2;
  border-radius: 15px;
  height: 30px;
  display: flex;
  align-items: center;
  padding: 0 14px;
  font-weight: 600;
  width: fit-content;
}

// .super-admin-wrap__table th.sortable span {
//   background-image: url('/assets/images/icons/sort.svg') !important;
//   background-repeat: no-repeat;
//   height: 18px;
//   width: 18px;
//   margin-bottom: -4px;
// }

.h-48 {
  height: 48px !important;
}

.h-64 {
  height: 48px !important;
}

.w-480 {
  width: 480px !important;
}

.w-350 {
  width: 350px !important;
}

.ml-40 {
  margin-left: 40px !important;
}

.ml-16 {
  margin-left: 16px !important;
}

.ml-8 {
  margin-left: 8px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.mt-i-40 {
  margin-top: -40px !important;
}
.mt-10 {
  margin-top: 10px !important;
}

.mt-18 {
  margin-top: 18px !important;
}
.invoice-label {
  color: #333333 !important;
  width: 140px !important;
}

.invoice-textbox {
  border: 1px !important;
  border-radius: 24px !important;
  background: #f2f2f2 !important;
}

.invoice-select {
  border: 1px !important;
  border-radius: 24px !important;
  background: #f2f2f2 !important;
  background-repeat: no-repeat !important;
  background-position-x: 96% !important;
  background-position-y: 7px !important;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>") !important;
}

.support-status {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 10px;
  border-radius: 16px;
  flex: none;
  order: 0;
  flex-grow: 0;
  font-weight: 600;
  width: fit-content;
  &-pending {
    background: #eeeefb;
    border: 1px solid #9b9ae6;
    color: #5856d6;
  }
  &-in {
    background: linear-gradient(0deg, rgba(255, 182, 0, 0.1), rgba(255, 182, 0, 0.1)), #ffffff !important;
    border: 1px solid rgba(255, 182, 0, 0.6) !important;
    color: #ffb600 !important;
    height: auto !important;
    border-radius: 16px !important;
  }
  &-resolved {
    background: #ebfbfa;
    border: 1px solid;
    // border-radius: 16px;
    color: #00cdc2;
  }
}

.sub-header-level2 {
  font-size: 20px;
  font-weight: bolder;
}

.support-user-avatar {
  img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
}

pr-20 {
  padding-right: 20px;
}

.h-48 {
  height: 48px !important;
}

.w-480 {
  width: 480px !important;
}

.w-350 {
  width: 350px !important;
}

.pl-8 {
  padding-left: 8px !important;
}

.pl-20 {
  padding-left: 20px !important;
}

.ml-24 {
  margin-left: 24px !important;
}

.px-24 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.ml-8 {
  margin-left: 8px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.mb-20 {
  margin-bottom: 20px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mb-40 {
  margin-bottom: 40px !important;
}

.ml-2 {
  margin-left: 2px !important;
}

.mt-6 {
  margin-top: 6px !important;
}

.mt-10 {
  margin-top: 10px !important;
}

.mt-12 {
  margin-top: 12px !important;
}

.mt-16 {
  margin-top: 16px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

.mt-30 {
  margin-top: 30px !important;
}

.mt-32 {
  margin-top: 32px !important;
}

.mt-40 {
  margin-top: 40px !important;
}

.mt-44 {
  margin-top: 44px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.mt-60 {
  margin-top: 60px !important;
}

.mt-80 {
  margin-top: 80px !important;
}

.mt-98 {
  margin-top: 98px !important;
}

.mt-150 {
  margin-top: 150px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-28 {
  font-size: 28px !important;
}

.fs-30 {
  font-size: 30px !important;
}

.fs-36 {
  font-size: 36px !important;
}

.fs-48 {
  font-size: 48px !important;
}

.support-ticket-title {
  display: flex;
  align-items: center;
  vertical-align: middle;
}

.text-neutral-50 {
  color: #808080;
}

.text-neutral-70 {
  color: #4d4d4d;
}

.text-neutral-80 {
  color: #333333;
}

.text-neutral-90 {
  color: #1a1a1a;
}

.fw-500 {
  font-weight: 500;
}

.text-black-21 {
  color: #22272f;
}

.text-black-20 {
  color: #454f5f;
}

.text-gray-40 {
  color: #5f6969;
}

.text-gray-90 {
  color: #181b1b;
}

.text-green-20 {
  color: #4da9aa !important;
}

.text-primary-40 {
  color: #02b9b0 !important;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.font-italic {
  font-style: italic;
}

.font-opensans {
  font-family: 'Open Sans', sans-serif !important;
}

.font-generalsans {
  font-family: 'General Sans' !important;
}
.not-applicaple {
  border: 1px solid #9b9ae6;
  padding: 2px 4px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Open Sans', sans-serif;
  color: #5856d6;
  background: #eeeefb;
}

.btn-sign-un-google {
  background: #f2f2f2 !important;
  font-family: 'Quicksand', sans-serif !important;
  font-weight: 500 !important;
  border-radius: 30px !important;
  font-size: 16px !important;
  color: #4d4d4d !important;
}

.btn-sign-un {
  background: #4da9aa !important;
  font-family: 'Quicksand', sans-serif !important;
  font-weight: 500;
  font-size: 16px !important;
  color: #fff !important;
  border-radius: 8px !important;
}

.align-items-center {
  align-items: center;
}

.text-align-right {
  text-align: right;
}

.bs-gutter-0 {
  --bs-gutter-x: 0 !important;
}
.label-privacy {
  font-family: 'Quicksand';
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  color: #333333;
}

.sign-up-label {
  font-family: Quicksand;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: #4d4d4d;
}

.sign-up-textbox {
  border-radius: 8px 0px 0px 0px !important;
  border: 1px 0px 0px 0px !important;
  opacity: 0px !important;
  border: 1px solid #4da9aa !important;
  color: #22272f !important;
  background: #fff !important;
  margin-top: 16px;
}

.survey-textbox {
  padding: 10px 16px 10px 16px;
  gap: 0px;
  border-radius: 30px;
  border: 1px solid #737373;
  font-family: Quicksand;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #22272f;
  background: #fff;
  cursor: pointer;
}

.survey-textbox-active {
  border: 1px solid #02b9af !important;
}

.label-skip {
  font-family: Quicksand;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  display: flex;
  align-items: center;
}
.btn-skip {
  font-family: Quicksand;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  background: unset;
  border: none;
  height: 100%;
}

.btn-continue {
  padding: 10px 16px 10px 16px !important;
  border: none;
  border-radius: 30px !important;
  background: #02b9af !important;
  color: #fff !important;
  font-family: Quicksand;
  font-size: 16px;
  font-weight: 700;
  height: 44px;

  &:disabled {
    cursor: not-allowed;
    background: #e5e5e5 !important;
    color: #999999 !important;
  }
}

.mr-24 {
  margin-right: 24px;
}

.mr-6 {
  margin-right: 6px;
}

.mr-12 {
  margin-right: 12px;
}

.btn-go-back {
  background: #fff !important;
  border: none;
  width: 24px;
  height: 24px;
}
.bg-white {
  background: #fff !important;
}

.new-dashboard-card-header {
  display: flex;
  justify-content: center;
}

.new-dashboard-card-item {
  border: 1px #b3bebe solid;
  border-radius: 12px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 9px !important;
}

.new-dashboard-employees {
  border: 1px #defce9 solid;
  border-radius: 8px;
  padding: 2px 14px;
  font-weight: 600;
  font-size: 16px;
  text-align: left;
  color: #149041;
  background-color: #defce9;
}

.new-dashboard-employees-red {
  border: 1px #fef1f1 solid;
  border-radius: 8px;
  padding: 2px 14px;
  font-weight: 600;
  font-size: 16px;
  text-align: left;
  color: #b11b1b;
  background-color: #fef1f1;
}

.new-dashboard-payroll {
  border: 1px #b3bebe solid;
  border-radius: 8px;
  padding: 2px 14px;
  font-weight: 600;
  font-size: 16px;
  text-align: left;
  color: #1a1a1a;
  background-color: #f9fcfc;
}

.new-dashboard-btn-view-more-gray {
  border: 1px #b3bebe solid;
  height: 36px;
  border-radius: 12px;
  font-weight: 600;
  font-size: 14px;
  color: #1a1a1a;
  padding: 0 12px;
  background-color: #f9fcfc;
  margin-bottom: 0px;
  margin-top: auto;

  &:hover {
    background-color: #F2F5F5;
  }

  &:focus {
    outline: 2px solid #02B9B0;
  }
}

.new-dashboard-btn-view-more-green {
  border: 1px #02b9b0 solid;
  height: 36px;
  border-radius: 12px;
  font-weight: 600;
  font-size: 14px;
  color: #02b9b0;
  padding: 0 12px;
  background-color: #ffffff;
  margin-bottom: 0px;
  margin-top: auto;

  &:hover {
    border: 1px solid #029991;
    outline: 1px solid #029991; ;
  }
}