// for date picker

.keclick-date-picker-container {
  display: flex;
  align-items: center;
  width: 100vw;
  height: 40px;
  border-radius: 8px;
  position: relative;

  &.apply-shift-template {
    width: auto !important;

    input {
      box-shadow: 0 0 0 1px rgba(102, 51, 153, 0.2) !important;
    }
  }

  .date-input {
    width: 100%;
    height: 100%;
    position: relative;

    .react-datepicker-wrapper {
      width: 100% !important;
    }

    input {
      width: 100%;
      height: 40px;
      border: none;
      background: transparent !important;
      z-index: 1;
      position: relative;
      padding: 0 12px;
    }

    input:disabled {
      background: #b3b3b3 !important;
    }

    textarea:focus,
    input:focus {
      outline: none;
    }
  }

  .icon {
    position: absolute;
    right: 12px;
    z-index: 0;

    img {
      width: 24px;
    }
  }

  .rdtPicker {
    border: 1px solid #5856d6;
    border-radius: 4px;
    padding: 0;
  }

  .rdtDays {
    thead > tr {
      border-bottom: 1px solid #5856d6;

      .dow {
        color: #808080;
      }
    }

    thead > tr:first-child {
      position: relative;
      height: 52px;
      border-bottom: 2px solid #5856d6;
      color: #808080;

      .rdtPrev {
        position: absolute;
        right: 30px;
        border: none;
        padding-top: 7px;

        &:hover {
          background: transparent;
        }
      }

      .rdtSwitch {
        position: absolute;
        text-align: left;
        left: 10px;
        border: none;
        padding-top: 15px;
        width: 200px;

        &:hover {
          background: transparent;
        }
      }

      .rdtNext {
        position: absolute;
        right: 10px;
        border: none;
        padding-top: 7px;

        &:hover {
          background: transparent;
        }
      }
    }

    .rdtDay {
      &:hover {
        background: transparent;
        color: #5856d6;
        font-weight: bold;
      }
    }

    .rdtToday {
      color: #1a1a1a;
      position: relative;

      &:hover {
        background: transparent;
      }

      &:before {
        border-bottom: none;
      }

      &:after {
        content: '';
        width: 30px;
        height: 30px;
        border: 1px solid #1a1a1a;
        display: block;
        position: absolute;
        border-radius: 100%;
        top: -1px;
        left: 3px;
      }
    }

    .rdtActive {
      position: relative;
      background: transparent;
      color: #fff !important;

      &:hover {
        color: #fff;
        font-weight: 500;
      }

      &:after {
        content: '';
        width: 30px;
        height: 30px;
        display: block;
        position: absolute;
        background: #5856d6;
        border-radius: 100%;
        top: -1px;
        left: 3px;
        z-index: -1;
      }
    }
  }

  .rdtMonths,
  .rdtYears {
    thead > tr {
      border-bottom: 1px solid #5856d6;
    }

    thead > tr:first-child {
      position: relative;
      height: 52px;
      border-bottom: 2px solid #5856d6;
      color: #808080;

      .rdtPrev {
        position: absolute;
        right: 30px;
        border: none;
        padding-top: 7px;

        &:hover {
          background: transparent;
        }
      }

      .rdtSwitch {
        position: absolute;
        text-align: left;
        left: 10px;
        border: none;
        padding-top: 15px;
        width: 200px;

        &:hover {
          background: transparent;
        }
      }

      .rdtNext {
        position: absolute;
        right: 10px;
        border: none;
        padding-top: 7px;

        &:hover {
          background: transparent;
        }
      }
    }

    .rdtActive {
      position: relative;
      background: transparent !important;

      &:hover {
        color: #fff;
        font-weight: 500;
      }

      &:after {
        content: '';
        width: 60px;
        height: 30px;
        display: block;
        position: absolute;
        background: #5856d6;
        border-radius: 30px;
        top: 10px;
        left: 1px;
        z-index: -1;
      }
    }
  }
}

.ke-time-picker-container {
  position: relative;
  width: 100%;
  border-radius: 4px;
  background: #e5e5e5;

  input {
    padding-right: 30px;
    background: transparent !important;
    z-index: 1;
    cursor: pointer;
  }

  .icon {
    position: absolute;
    right: 10px;
    top: 8px;
    z-index: 0;
  }
}

.timepicker-ui-dot,
.timepicker-ui-clock-hand,
.timepicker-ui-circle-hand {
  background-color: #5856d6 !important;
}

.timepicker-ui-hour.active {
  color: #5856d6 !important;
  background-color: #ffcccc !important;
}

.timepicker-ui-pm.active {
  color: #5856d6 !important;
  background-color: #ffcccc !important;
}

.timepicker-ui-am.active {
  color: #5856d6 !important;
  background-color: #ffcccc !important;
}

.timepicker-ui-cancel-btn,
.timepicker-ui-ok-btn {
  color: #5856d6 !important;
  font-weight: 600;
}

.timepicker-ui-am {
  border: 1px solid #d6d6d6 !important;
}

.timepicker-ui-tips-wrapper {
  background: #f2f2f2;
  border-radius: 100%;
}

// redesign date picker
.keclick-datepicker-wrapper {
  .ant-picker {
    width: 100%;
    font-size: 16px;
    line-height: 24px;
    padding: 8px 12px;
    border-radius: 12px;
    transition: all 0.2s ease;
    border: 1px solid #b3bebe;
    background-color: #ffffff;
    cursor: pointer !important;

    &::placeholder {
      font-size: 16px;
      line-height: 24px;
      color: #718096;
      cursor: pointer !important
    }

    &:hover {
      border: 1px solid #1a1a1a;
      background-color: #f2f5f5;
    }

    &.ant-picker-focused {
      border-color: #1a1a1a;
      box-shadow: none;
    }

    .ant-picker-input {
      input {
        font-family: 'Quicksand', sans-serif;
        font-size: 16px;
        line-height: 24px;
        font-weight: 500;
        color: #1a1a1a;
        cursor: pointer;

        &::placeholder {
          color: #b3bebe;
        }
      }

      .ant-picker-suffix {
        color: #2e3333;
      }
    }
  }
}

.redesign-datepicker {
  padding: 4px !important;

  .ant-picker-panel-container {
    border-radius: 16px !important;
    border: 1px solid #e2e8f0;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
    overflow: hidden;
    padding: 0;
    background: #ffffff;
  }

  .ant-picker-panel {
    border: none !important;
  }

  .ant-picker-header {
    border: none;
    padding: 0 0 10px 0;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    font-family: 'Quicksand', sans-serif !important;


    .ant-picker-header-view {
      width: 100%;
      flex: 1;
      font-weight: 500;
      font-size: 16px;
      color: #1a1a1a;
      text-align: center;
    }

    .ant-picker-header-prev-btn,
    .ant-picker-header-next-btn {
      position: static;
      padding: 8px;
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #b3bebe;
      border-radius: 12px;
      color: #1a1a1a;

      &:hover {
        background: #f2f5f5;
      }

      span.anticon {
        font-size: 12px;
      }
    }
  }

  .ant-picker-body {
    padding: 8px 0 0;

    table {
      thead {
        tr {
          th {
            height: 32px;
            padding: 0;
            font-size: 12px;
            font-weight: 500;
            color: #718096;
            font-family: 'Quicksand', sans-serif !important;
          }
        }
      }

      tbody {
        tr {
          td {
            height: 36px !important;
            
            &:hover {
              background-color: rgba(242, 245, 245, 1);
              border-radius: 8px !important;
            }
            padding: 2px;
            &.ant-picker-cell-today {
              .ant-picker-cell-inner {
                &::before {
                  border-color: #02b9b0!important;
                }
              }
            }
            &.ant-picker-cell-selected {
              .ant-picker-cell-inner {
                background-color: #02b9b0 !important;
              }
            }
            &.ant-picker-cell {
              .custom-cell {
                width: 100%;
                height: 100%;
                border-radius: 8px !important;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: auto;
                font-size: 14px;
                color: rgba(26, 26, 26, 0.7);
                cursor: pointer;
                transition: all 0.2s;

                

                &.selected {
                  border: 2px solid #02b9b0 !important;
                  color: rgba(26, 26, 26, 1);
                }
              }

              &.ant-picker-cell-disabled {
                .custom-cell {
                  background: #e2e8f0 !important;
                  color: #718096;
                  cursor: not-allowed;

                  &:hover {
                    background: #e2e8f0 !important;
                  }
                }
              }
            }
            &.ant-picker-cell-inner {
              background-color: red !important;
              &::before {
                border-color: #02b9b0;
              }
            }
          }
        }
      }
    }
  }

  // Hide footer
  .ant-picker-footer {
    display: none !important;
  }
}
